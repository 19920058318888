// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/i18n.ts"
);
import.meta.hot.lastModified = "1690787014856.6445";
}
// REMIX HMR END

import type { InitOptions } from 'i18next';

export default {
  supportedLngs: ['en', 'ja'],
  fallbackLng: ['en', 'ja'],
  defaultNS: 'common',
  ns: ['common', 'consumer'],
  react: { useSuspense: false },
} satisfies InitOptions;
